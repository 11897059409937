import type { PropsWithChildren } from 'react';

import { cn } from '#app/utils/misc';
import React from 'react';
import { isMobile } from 'react-device-detect';

import { Icon } from '../ui/icon';


interface Props {
  childrenClassName?: string;
}
const ScrollKeywords: React.FC<PropsWithChildren<Props>> = ({ children, childrenClassName }) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const [rightBlur, setRightBlur] = React.useState(false);
  const [leftBlur, setLeftBlur] = React.useState(false);
  
  const onScroll = (e:React.UIEvent<HTMLDivElement, UIEvent>) => {
    if ((e.currentTarget.scrollWidth - e.currentTarget.scrollLeft) - e.currentTarget.clientWidth < 10) {
      setRightBlur(false);
      return;
    }
    if (e.currentTarget.scrollLeft < 10) {
      setLeftBlur(false);
      return;
    }
    setLeftBlur(true);
    setRightBlur(true);
  };
  React.useEffect(() => {
    if (scrollRef.current) {
      if(scrollRef.current.scrollWidth > scrollRef.current.clientWidth) {
        setRightBlur(true);
      }
    }
  }, []);

  return (
    <>
      {
        !isMobile && leftBlur && 
          <div 
            className='rounded-full border border-solid border-gray-20 p-3 grid items-center hover:cursor-pointer active:bg-gray-20' 
            onClick={() => {
              if (scrollRef.current) {
                scrollRef.current.scrollTo({behavior: 'smooth', left: scrollRef.current.scrollLeft - 300});
              }
            }}>
            <Icon name='regular-backward' size='medium' />
          </div>
      }
      <div className={
        cn(
          'whitespace-nowrap overflow-auto scrollbar-hide',
          childrenClassName,
        )
      } onScroll={onScroll} ref={scrollRef}>
        {children}
      </div>
      {
        !isMobile && rightBlur && 
          <div className='rounded-full border border-solid border-gray-20 p-3 grid hover:cursor-pointer active:bg-gray-20'
            onClick={() => {
              if (scrollRef.current) { 
                scrollRef.current.scrollTo({behavior: 'smooth', left: scrollRef.current.scrollLeft + 300});
              }
            }}>
            <Icon name='regular-forward' size='medium' />
          </div>
      } 
    </>
  );
};

export default ScrollKeywords;
